$(function () {
	/**********************************************************************************************/
	/**********************************          HEADER          **********************************/
	/**********************************************************************************************/
	$(".header__burger").click(function () {
		$("body").addClass("menu-on");
	});

	/**********************************************************************************************/
	$(".mobmenu__close").click(function () {
		$("body").removeClass("menu-on");
	});

	/**********************************************************************************************/
	$(".menu-item.current-menu-parent").closest(".menu-item-has-children").addClass("current-menu-parent");

	/**********************************************************************************************/
	if ($(".main").hasClass("page-recipe")) {
		$(".menu__list li:first-child").addClass("current-menu-parent");
	}




	/**********************************************************************************************/
	/**********************************          SCROLL          **********************************/
	/**********************************************************************************************/
	function scrollToTarget(target) {
		let adminOffset = $("body").hasClass("admin-bar") ? $("#wpadminbar").height() : 0;
		let offset = 30;
		let top = !target ? 0 : $(target).offset().top - $(".header").height() - adminOffset - offset;
		$("html").animate({ scrollTop: top + 'px' }, "300");
	}

	/**********************************************************************************************/
	$(document).ready(function () {
		setTimeout(function () {
			if (window.location.hash)
				scrollToTarget(window.location.hash);
		}, 100);
	});

	/**********************************************************************************************/
	$(".scroll-link").click(function (e) {
		e.preventDefault();
		scrollToTarget($(this).attr("href"));
	});




	/**********************************************************************************************/
	/**********************************          POPUPS          **********************************/
	/**********************************************************************************************/
	$("body").on("click", ".popup-link", function (e) {
		e.preventDefault();
		$($(this).attr("href")).addClass("show");
		$("body").removeClass("menu-on").addClass("popup-on");
	});

	/**********************************************************************************************/
	$(".popup__close").click(function () {
		$(".popup.show").removeClass("show");
		$("body").removeClass("popup-on");
	});

	/**********************************************************************************************/
	$(".overlay").click(function () {
		$(".popup.show, .recipes-filter__popup.show").removeClass("show");
		$("body").removeClass("popup-on filter-on");
	});




	/**********************************************************************************************/
	/**********************************          COOKIE          **********************************/
	/**********************************************************************************************/
	function setCookie(cname, cvalue, exdays, path) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=" + path;
	}

	/**********************************************************************************************/
	$(".cookie-message__btn").click(function () {
		setCookie("bonfesto_cookie_policy", "hide", 30, "/");
		$(".cookie-message").addClass("hide");
	});




	/**********************************************************************************************/
	/**********************************       CONTACT FORM       **********************************/
	/**********************************************************************************************/
	$("#contact-form").validate({
		submitHandler: function (form) {
			$(form).find(".formarea__submit").addClass("disabled");

			var data = new FormData();
			data.append('name', $("#contact-form-name").val());
			data.append('tel', $("#contact-form-tel").val());
			data.append('email', $("#contact-form-email").val());
			data.append('txt', $("#contact-form-txt").val());

			$.ajax({
				method: 'POST',
				url: '/wp-content/themes/factory16/inc/actions/contact-form.php',
				enctype: 'multipart/form-data',
				cache: false,
				contentType: false,
				processData: false,
				data: data,
				success: function (data) {
					data = JSON.parse(data);
					$(form).find(".formarea__submit").removeClass("disabled");
					$(form).find(".formarea__response").removeClass("error").addClass("show").text(data.response);
					$(form)[0].reset();
					setTimeout(() => {
						$(form).find(".formarea__response").removeClass("show").text("");
					}, 5000);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					$(form).find(".formarea__submit").removeClass("disabled");
					$(form).find(".formarea__response").addClass("error show").text(errorThrown);
					console.log(textStatus, errorThrown);
					$(form)[0].reset();
					setTimeout(() => {
						$(form).find(".formarea__response").removeClass("error show").text("");
					}, 5000);
				},
			});
		},
	});




	/**********************************************************************************************/
	/**********************************          SEARCH          **********************************/
	/**********************************************************************************************/
	$(".header-search__submit").click(function () {
		$(this).closest("form").submit();
	});

	/**********************************************************************************************/
	$(".header-search__icon").click(function () {
		$(".header-search").addClass("show");
	});

	/**********************************************************************************************/
	$("body").on("click", function (e) {
		if ($(e.target).closest(".header-search").length == 0)
			$(".header-search").removeClass("show");
	});


	/**********************************************************************************************/
	/**********************************************************************************************/
	$("#search-form").each(function () {
		$(this).validate({
			submitHandler: function (form) {
				$(form).find(".formarea__submit").addClass("disabled");
				location.href = "/search?word=" + $(form).find("input[type='text']").val();
			},
		});
	});
});